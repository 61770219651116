<template>
    <div class="pre-loader" style="display: none;">
        <div
            style="display: flex; justify-content: center; align-items: center; position: fixed; top: 0px; right: 0px; z-index: 999999; width: 100%; height: 100%; background: hsla(0, 9%, 96%, .3);">
            <div
                style="border-width: 16px; border-style: solid; border-color: #d1af77 rgb(243, 243, 243) rgb(243, 243, 243); border-image: initial; border-radius: 50%; width: 120px; height: 120px; animation: 2s linear 0s infinite normal none running spin;">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Preloader'
}
</script> 
<style>
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>