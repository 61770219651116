import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import router from './router/index'
import store from '@/state/store'
import vco from "v-click-outside"
import en from './locales/en.json'
import ar from './locales/ar.json'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import "@/design/index.scss";
import '../src/assets/css/index.defer.min.css'
import Sparkline from 'vue-sparklines'
import '../src/design/custom/plugins/line-awesome-1.3.0/css/line-awesome.min.css'
import { initFirebaseBackend } from './authUtils'
import { configureFakeBackend } from './helpers/fake-backend';
import { MdButton, MdContent, MdTabs } from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import VueMaterial from 'vue-material'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import VuePusher from 'vue-pusher';
import Multiselect from 'vue-multiselect'
import VueCookie from 'vue-cookie'
import HighchartsVue from 'highcharts-vue'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

Vue.use(BootstrapVue);

Vue.config.productionTip = false
Vue.use(VueMaterial)
Vue.use(MdButton)
Vue.use(MdContent)
Vue.use(MdTabs)
Vue.use(Vuelidate);
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(require('vue-chartist'))
Vue.use(VueToast)
Vue.use(vco)
Vue.use(Sparkline)
Vue.use(VueI18n);
Vue.use(VueAxios, axios)
Vue.use(VueCookie);
Vue.use(HighchartsVue)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.use(VuePusher, {
  api_key: 'ab9a5e8365c71b1df075',
  options: {
    cluster: 'ap2',
  }
});
Vue.component('apexchart', VueApexCharts)
Vue.component('multiselect', Multiselect)
Vue.prototype.$appUrl = 'https://jsky.rs4it.com/api_v2/public' //'http://api.jsky.rs4it.com'
Vue.prototype.$appBusinessUrl =  'https://business.jsky.rs4it.com' //'http://api.jsky.rs4it.com'
function loadLocaleMessages() {
  const locales = [{ en: en }, { ar: ar }]
  const messages = {}
  locales.forEach(lang => {
    const key = Object.keys(lang)
    messages[key] = lang[key]
  })
  return messages
}
const i18n = new VueI18n({
  lazy: true,
  locale: 'ar',
  fallbackLocale: 'ar',
  messages: loadLocaleMessages()
});
new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
