<script>
import appConfig from "@/app.config";
import Preloader from './router/layouts/Preloader.vue'
export default {
  name: "app",
  components: { Preloader },
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  mounted() {
    this.$pusher.subscribe('notifications-channel').bind('new-notification', (data) => {
      var message = JSON.parse(data);
      if (message.type != 'send') {
        this.$toast.open({
          message: 'New Message !',
          type: 'info',
          position: 'top-left',
          duration: 2500
        });
      }
    });
  }
};
</script>

<template>
  <div id="app">
    <Preloader />
    <router-view></router-view>
  </div>
</template>
